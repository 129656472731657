<template>
  <div
    class="flex w-100 h-100 d-flex justify-content-center align-items-center"
  >
    <div class="d-flex flex-column align-items-center">
      <b-spinner class="mb-1" variant="primary" />
      Authentication into your apps
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import { checkAuthorizeRole } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  async mounted() {
    const { token } = this.$route.query
    // const url = `${process.env.VUE_APP_BASE_API_URL}/me/${token}`
    const url = `${process.env.VUE_APP_BASE_API_URL}/users/profile`

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      timeout: 5000,
    }

    console.log(this.$route.query)
    console.log(token)

    // get user data with token
    axios
      .get(url, config)
      .then((response) => {
        this.isLoading = false
        let userData = response.data.data
        useJwt.setToken(token)
        // useJwt.setRefreshToken(response.data.refreshToken)

        const userAbility = [
          {
            action: 'manage',
            subject: 'all',
          },
        ]

        userData = {
          ...userData,
          ability: userAbility,
        }

        localStorage.setItem('userData', JSON.stringify(userData))
        this.$ability.update(userData.ability)

        // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
        if (
          userData.roles[0].name !== 'Super Admin' &&
          userData.school_id === null
        ) {
          this.$router.replace({ name: 'school-not-authorized' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Akses Ditolak',
                icon: 'UserIcon',
                variant: 'danger',
                text: 'Anda tidak memiliki akses untuk masuk ke halaman ini.',
              },
            })
          })
        } else {
          this.$router
            .replace({ name: checkAuthorizeRole('dashboard') })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.name}`,
                  icon: 'UserIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.roles[0].name}. Now you can start to explore!`,
                },
              })
            })
        }
      })
      .catch((error) => {
        this.isLoading = false
        this.showToast(
          'danger',
          'AlertTriangleIcon',
          'Uh Oh!',
          error.toString() === 'Error: timeout of 5000ms exceeded'
            ? 'Something went wrong, please try again later'
            : error.response.data.message
        )
      })
  },
}
</script>

<style lang="scss">
.h-100 {
  height: 100vh !important;
}
</style>
